<template>
	<div v-if="page" class="interventions-home">
		<h2 class="text-secondary">{{ page.title }}</h2>

		<div class="row mb-5">
			<div class="col-12 col-lg-8 order-1 order-lg-0">
				<div v-html="page.content"/>
			</div>
			<div class="col-12 col-lg-5 order-0 order-lg-1">
				<div v-if="page.videoEmbed">
					<MediaEmbed :page="page"/>
				</div>
			</div>
		</div>

		<Themes @click-theme="chooseTheme" slug="interventies" />
	</div>
</template>

<script>
    // @ is an alias to /src
    import Themes from '@/components/Themes.vue';
    import api from '@/data/api'
    import {themeMixin} from "@/mixins/chooseThemeMixin";
	import MediaEmbed from "@/components/MediaEmbed";

    export default {
        name: 'Home',
        components: {MediaEmbed, Themes},
        mixins: [themeMixin],
        data() {
            return {
                pageTitle: 'Interventies',
                page: null
            }
        },
        mounted() {
            api.fetchPage(5528).then(response => {
                this.page = response;
            });

            const breadcrumbs = [{'label': 'Home', 'path': '/'}, {'label': 'Acties'}];
            this.$store.commit('SET_BREADCRUMBS', {breadcrumbs});
        }
    }
</script>
